import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// import assets
import TravelSummaryIcon from 'assets/images/travel-summary-blue.svg'
import SavingsOpportunityIcon from 'assets/images/savings-opportunity-blue.svg'
import ReportingHubIcon from 'assets/images/reporting-hub-blue.svg'
import SupportIcon from 'assets/images/support-blue.svg'

// import components
import LandingLayout from 'views/Landing/LandingLayout'
import { useAppSelector } from 'hooks'
import LandingSearch from './Search'

const Landing = () => {
  const { t } = useTranslation()
  const full_name = useAppSelector((state) => state.userInfo.userAuthInfo?.full_name)

  const routes = [
    {
      title: t('travelSummaryHeader'),
      uri: '/travel-summary',
      icon: TravelSummaryIcon,
      disabled: false,
    },
    {
      title: t('savingsOpportunityHeader'),
      uri: '/savings-opportunity',
      icon: SavingsOpportunityIcon,
      disabled: false,
    },
    {
      title: t('reportingHubHeader'),
      uri: '/reporting-hub',
      icon: ReportingHubIcon,
      disabled: false,
    },
    {
      title: t('supportHeader'),
      uri: '/support',
      icon: SupportIcon,
      disabled: true,
    },
  ]

  return (
    <LandingLayout pageName="landing">
      {/* Light weight hero section :) */}
      <div className="flex flex-col items-center h-2/10">
        <div className="flex flex-col items-center justify-evenly h-9/10">
          <h2 className="font-medium 2xl:leading-relaxed xl:leading-normal tracking-normal 2xl:text-[28px] xl:text-[22px] lg:text-[18px] md:text-[16px] text-dark-blue">
            {t('welcome')} {full_name?.split(' ')[0]}
          </h2>
          <LandingSearch />
          <p className="text-blue 2xl:font-normal 2xl:leading-relaxed xl:leading-normal 2xl:text-lg xl:text-[16px] text-xs">
            {t('selectASection')}
          </p>
        </div>
      </div>
      {/* Light weight hero section :) */}
      <div className="flex justify-center 2xl:h-6/10 lg:h-6/10 md:h-6/10 sm:h-6/10">
        <div className="grid w-6/12 md:w-9/10 h-full grid-cols-2 grid-rows-2 2xl:gap-6 xl:gap-5 gap-3 sm:gap-2">
          {routes.map((route) => {
            return (
              <NavLink
                to={`${route.disabled ? '#' : `${route.uri}`}`}
                key={route.title}
                className={`${
                  route.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                } relative flex flex-col justify-center bg-white 2xl:rounded-lg xl:rounded-lg rounded p-2`}
              >
                {route.disabled && (
                  <div className="absolute flex justify-end w-full 2xl:h-1/10 top-3 right-2">
                    <div className="flex justify-end px-2 2xl:text-base xl:text-sm text-[0.5rem] 2xl:font-medium font-light text-gray-400 rounded-full bg-lightest-blue w-5/7">
                      {t('comingSoon')}
                    </div>
                  </div>
                )}
                <img
                  src={`${route.icon}`}
                  alt=''
                  className={`${
                    route.disabled
                      ? 'opacity-20 h-5/10 sm:h-65/10'
                      : 'h-5/10 sm:h-75/10'
                  } w-full`}
                />
                <div className="flex items-end justify-center px-4 h-2/10">
                  <h3
                    className={`${
                      route.disabled ? 'opacity-40' : ''
                    } 2xl:text-xl xl:text-sm text-xs md:text-[0.6rem] 2xl:tracking-wide font-medium`}
                  >
                    {route.title}
                  </h3>
                </div>
              </NavLink>
            )
          })}
        </div>
      </div>
    </LandingLayout>
  )
}

export default Landing
